import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { graphql } from "gatsby";
import AfspraakBtn from '../components/AfspraakBtn';

// eslint-disable-next-line
export const ReparatiesStoringenPageTemplate = class extends React.Component {

	componentDidMount() {
	}

	render() {
		return (
      <section className='container'>
        <h1 className="title mt-4 mb-3"><b>Reparaties & Storingen</b></h1>
        <h3 className="title mt-4 mb-3">
					U wilt genieten van een gezellig haardvuur, maar uw haard doet het niet?
					Dat is natuurlijk heel vervelend en komt altijd ongelegen.
        </h3>
				<h3 className="title mt-4 mb-3">
				Hans Haardenservice helpt u graag verder om deze storing te verhelpen.
Plan een afspraak in en ik kom z.s.m. langs om het probleem op te lossen. Zo kan u weer optimaal genieten van uw sfeervolle haard.
        </h3>
        <div className="hh-tarief mt-6">
          <h4>Tarief reparaties en storingen</h4>
          <ul>
            <li>€72/uur (minimaal tarief 1 uur, daarna per half uur)</li>
            <li>Voorrijkosten €70</li>
            <li>Onderdelen en stukken niet inbegrepen</li>
					</ul>
        </div>
				<div className="row justify-content-center mt-5">
          <AfspraakBtn />
        </div>

        <div className="hh-tarief-info mt-5">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
            <p>Deze prijzen zijn exclusief btw <br />
&gt;100km vanuit Sint Katelijne Waver is een meerprijs van €25
Kosten van eventueel te vervangen onderdelen , steeds in overleg. <br />
Direct te betalen na voltooing, betaling mogelijk met cash, bancontact of Payconiq</p>
              </div>
            </div>
        </div>
      </section>
    );
  }
}

ReparatiesStoringenPageTemplate.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

const ReparatiesStoringenPage = ({data}) => {
	const { frontmatter } = data.markdownRemark;
	return (
		<Layout pageTitle='Hans Haardenservice • Reparaties & Storingen' pageDescription={frontmatter.description}>
			<ReparatiesStoringenPageTemplate />
		</Layout>
	)
};

ReparatiesStoringenPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default ReparatiesStoringenPage

export const pageQuery = graphql`
  query ReparatiesStoringenPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "reparaties-storingen-page" } }) {
      frontmatter {
        title
        description
      }
    }
  }
`;
